import * as React from "react";
import { useDispatch } from "react-redux";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import logo from "../../assets/images/logo_cellcoin.png";
import { logout, setSidebarState } from "../../redux/actions-exporter";
import {
  Box,
  Container,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LogoutIcon from "@mui/icons-material/Logout";
import SecurityIcon from "@mui/icons-material/Security";
import BlockIcon from "@mui/icons-material/Block";
import GavelIcon from "@mui/icons-material/Gavel";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

const theme = createTheme({
  direction: "rtl",
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export default function Menu({ setItem }) {
  const arr = [
    { icon: <GavelIcon />, title: "תקנון" },
    { icon: <SecurityIcon />, title: "רכישה מאובטחת" },
    { icon: <BlockIcon />, title: "מדיניות ביטול עסקה" },
    { icon: <AlternateEmailIcon />, title: "יצירת קשר" },
    { icon: <AccountBalanceWalletIcon />, title: "ניהול כרטיסי אשראי" },
  ];
  const dispatch = useDispatch();

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mx: "auto",
              background: "var(--secondary)",
              width: "66%",
              borderRadius: "50%",
              aspectRatio: "3/2",
              boxShadow: "0px 0px 20px 0px rgb(42 134 159 / 10%)",
            }}
          >
            <img
              src={logo}
              style={{
                objectFit: "contain",
                width: "80%",
              }}
            />
          </Box>

          <MenuList
            sx={{
              "& .MuiListItemText-root": { textAlign: "start !important" },
            }}
          >
            {arr.map((item) => (
              <MenuItem sx={{ my: 2 }} onClick={() => setItem(item.title)}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  sx={{
                    "&>span": { fontSize: "1.5rem !important" },
                  }}
                >
                  {item.title}
                </ListItemText>
              </MenuItem>
            ))}

            <Divider />
            <MenuItem
              onClick={() => {
                dispatch(logout());
                dispatch(setSidebarState(false));
              }}
            >
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                sx={{
                  "&>span": { fontSize: "1.5rem !important" },
                }}
              >
                התנתקות
              </ListItemText>
            </MenuItem>
          </MenuList>
        </Container>
      </ThemeProvider>
    </CacheProvider>
  );
}
