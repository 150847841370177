import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as Locate } from "../../assets/icons/locate.svg";

import Header from "../header/Header";
import { getProductById, openSidebar } from "../../redux/actions-exporter";

import "../category/category.css";

export default function ProductNetworkss() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = location?.state;
  const product = useSelector((state) => state.product.product);
  const [merchants, setMerchants] = useState([]);

  useEffect(() => {
    dispatch(getProductById(id));
  }, [id]); //eslint-disable-line

  useEffect(() => {
    const list = [];
    product?.merchants?.map((merchant) => {
      if (
        !list.includes(merchant) &&
        !merchant.title.includes("סגור") &&
        !merchant.title.includes("איטלקיה") &&
        merchant.type !== "hr"
      )
        list.push(merchant);
    });

    const groups = Object.values(
      list.reduce((acc, curr) => {
        const firstWord = curr.network?.name || curr.title;
        if (!acc[firstWord]) {
          acc[firstWord] = [];
        }

        acc[firstWord].push(curr);
        return acc;
      }, {})
    );
    setMerchants(groups.sort((a, b) => b.length - a.length));
  }, [product]);

  return (
    <div>
      {/* <Header isWallet={true} /> */}

      <div className="pageTemplate">
        <div className="container">
          <div className="vendorAllListBlock">
            <h6 className="module-heading__title">{product?.title}</h6>
            <ul className="categoryList">
              {merchants?.length > 0
                ? merchants.map((item) => (
                    <>
                      <li
                        className="categoryList__block"
                        onClick={() => {
                          navigate(`/product/${id}`, {
                            state: { id: id, network: item[0] },
                          });
                        }}
                      >
                        <div className="category-box__img">
                          <img
                            src={item[0].image}
                            className="img-fluid"
                            alt="My Awesome"
                          />
                        </div>
                        {item.length > 0 ? (
                          <>
                            <h6 className="category-box__title up">
                              {item[0].network?.name || item[0].title}
                            </h6>
                          </>
                        ) : (
                          <h6 className="category-box__title">
                            {item[0].title.length < 20
                              ? item[0].title
                              : item[0].title.slice(0, 19) + "..."}
                          </h6>
                        )}
                      </li>
                    </>
                  ))
                : ""}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
