import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Header from "../header/Header";
import { getUser } from "../../redux/API/user/user.action";

import "./PaymentApprove.css";
import { clearOrder, getOrder } from "../../redux/API/order/order.action";

// import qr from "../../assets/images/qr.jpg;

export default function PaymentApprove({ newMoney }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const order = useSelector((state) => state.order.order);
  const [orderAttempts, setOrderAttempts] = useState(0);
  useEffect(() => {
    dispatch(getUser());
  }, []); // eslint-disable-line

  useEffect(() => {
    let timer;
    if (!order.cards && orderAttempts < 4) {
      timer = setInterval(() => {
        dispatch(getOrder(order.id));
        setOrderAttempts(orderAttempts + 1);
      }, 5000);
    }
    return () => clearInterval(timer);
  }, [order?.cards, orderAttempts]); // eslint-disable-line

  useEffect(() => () => dispatch(clearOrder()), []); // eslint-disable-line

  return (
    <div>
      {/* <Header /> */}
      <div className="loadMoneyTemplate">
        <div className="container">
          <hr />
          <div className="templateHeading">
            <h1 className="templateTitle">{t("payment approve")}</h1>
          </div>
          <hr />
          <div className="paymentApproveContent">
            <div className="paymentApproveText">
              <h5 className="pelecard-page__title">שובר מולטיפאס - רעיונית</h5>
              <h6 className="pelecard-page__info">
                סכום החשבון: {order?.total || 0} ₪
              </h6>
              <h5 className="pelecard-page__title">
                תשלום בסך{" "}
                {order?.discountTotal !== 0
                  ? order?.discountTotal
                  : order?.total || 0}{" "}
                ₪ בוצע בהצלחה
              </h5>
              <h5 className="paymentApproveLine1">
                {order?.cards[0]?.cardNumber ? (
                  <>קוד מולטיפס: {order?.cards[0]?.cardNumber}</>
                ) : (
                  <>{t("Loading card")}...</>
                )}
              </h5>
              {order?.cards[0]?.pinCode ? (
                <>קוד: {order?.cards[0]?.pinCode}</>
              ) : (
                ""
              )}
              <h5 className="pelecard-page__info">(להצגה בקופה)</h5>
            </div>
            <div className="loadMoneyCard-btn">
              <Button
                className="blueBtn"
                onClick={() => {
                  navigate("/");
                }}
              >
                {t("end")}
              </Button>
              <Button
                className="blueBtn gift"
                onClick={() => {
                  navigate("/");
                }}
              >
                שתף כמתנה
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
