import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import Drawer from "@mui/material/Drawer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EmailShareButton, WhatsappShareButton } from "react-share";
import { EmailIcon, WhatsappIcon } from "react-share";

import { getOrder } from "../../../redux/actions-exporter";
import Header from "../../header/Header";

import "./styles.css";

const Index = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const currentOrder = useSelector((state) => state.order.order);
  const [timer, setTimer] = useState(false);
  const [order, setOrder] = useState(false);
  const [share, setShare] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [requestCount, setRequestCount] = useState(0);

  useEffect(() => {
    if (!timer && requestCount < 4) {
      setTimer(
        setInterval(() => {
          dispatch(getOrder(orderId));
          setRequestCount((prevCount) => prevCount + 1);
        }, 5000)
      );
    }
  }, [orderId, timer, requestCount, dispatch]); // eslint-disable-line

  useEffect(() => {
    if (!order?.cards?.length) {
      setOrder(currentOrder);
    }

    if (currentOrder?.cards?.length) {
      clearInterval(timer);
      setOrder(currentOrder);
    }
  }, [currentOrder, order, timer]); // eslint-disable-line

  return (
    <div className="pageTemplate">
      {/* <Header  isFull={false} /> */}
      <div className="container">
        <hr />
        <div className="templateHeading">
          <h1 className="templateTitle">{t("payment approve")}</h1>
        </div>
        <hr />
        <div className="paymentApproveContent">
          <div className="paymentApproveText">
            <h6 className="pelecard-page__info">
              סכום החשבון: {order?.total || 0} ₪
            </h6>
            <h5 className="pelecard-page__title">
              תשלום בסך ₪{order?.discountTotal || order?.total || 0} בוצע בהצלחה
              {/* תשלום בסך {order?.total * order?.metadata?.discount || 0} ₪ בוצע */}
            </h5>
            <h5 className="paymentApproveLine1">
              {order?.cards?.[0]?.cardNumber ? (
                <>קוד מולטיפס: {order?.cards?.[0]?.cardNumber}</>
              ) : (
                <>{t("Loading card")}...</>
              )}
            </h5>
            <h5 className="pelecard-page__info">(להצגה בקופה)</h5>
            {order?.cards?.[0]?.pinCode ? (
              <>{order?.cards?.[0]?.pinCode}</>
            ) : (
              ""
            )}
          </div>
          <div className="loadMoneyCard-btn">
            <Button
              className="blueBtn"
              onClick={() => {
                navigate("/");
              }}
            >
              {t("end")}
            </Button>
            {order?.cards?.[0]?.cardNumber ? (
              <Button
                className="blueBtn gift"
                onClick={() => {
                  // navigate("/");
                  setShare(true);
                }}
              >
                שתף כמתנה
              </Button>
            ) : (
              ""
            )}
            <Drawer
              className="callOrChat"
              PaperProps={{
                sx: {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                  width: "200px",
                  top: "400px",
                  left: "20px",
                  height: "100px",
                  margin: "auto",
                  borderRadius: "60px",
                  padding: "10px",
                },
              }}
              open={share}
              onClose={() => setShare(false)}
              anchor="left"
            >
              <a className="f6 grow no-underline br-pill ph3 pv2 mb2 dib ">
                <EmailShareButton
                  // className='share'
                  url={"#"}
                  subject="מתנה מ-CellCoin!"
                  body={`קוד מולטיפס (להצגה בקופה): ${
                    order?.cards?.[0]?.cardNumber || 12345678
                  } \nפינקוד: ${order?.cards?.[0]?.pinCode || 1234}`}
                >
                  <EmailIcon size={55} round />
                </EmailShareButton>
              </a>
              <a className=" f6 grow no-underline br-pill ph3 pv2 mb2 dib ">
                <WhatsappShareButton
                  // title="מתנה מ-CellCoin!"
                  // body={`קוד מולטיפס (להצגה בקופה): ${order?.metadata?.cardNumber || 12345678
                  //   } \nפינקוד: ${order?.metadata?.pinCode || 1234}`}
                  // url={"#"}
                  title="מתנה מ-CellCoin!"
                  separator={"\n"}
                  url={`קוד מולטיפס (להצגה בקופה): ${
                    order?.cards?.[0]?.cardNumber || 12345678
                  } \nפינקוד: ${order?.cards?.[0]?.pinCode || 1234}`}
                >
                  <WhatsappIcon size={55} round />
                </WhatsappShareButton>
              </a>
            </Drawer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
