import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import cx from "classnames";
import { ToastContainer } from "react-toastify";
import Footer from "./components/newFooter/Footer";
import Login from "./components/login/Login";
import Register from "./components/register/Register";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import UserProfile from "./components/userprofile/UserProfile";
import CategoryDetails from "./components/category/CategoryDetails";
import ProductDetails from "./components/product/ProductDetails";
import ProductNetworks from "./components/product/ProductNetworks";
import VendorsDetails from "./components/vendor/VendorDetails";
import Cart from "./components/cart/Cart";
import Wallets from "./components/wallets/Wallets";
import Payment from "./components/wallets/Payment";
import LoadMoney from "./components/wallets/LoadMoney";
import CreaditCard from "./components/wallets/CreaditCard";
import Dashboard from "./components/dashboard/Dashboard";
import CategoryList from "./components/category/CategoryList";
import Map from "./components/location/Map";
import Pay from "./components/cart/Pay";
import PaymentHistory from "./components/wallets/PaymentHistory";
import Checkout from "./components/cart/Checkout";
import PaymentCardHistory from "./components/wallets/PaymentCardHistory";
import BusinessPayment from "./components/wallets/BusinessPayment";
import PaymentApprove from "./components/wallets/PaymentApprove";
import Company from "./components/booking/Company";
import Product from "./components/booking/Product";
import BookingCart from "./components/booking/BookingCart";
import BookingCartDetail from "./components/booking/BookingCartDetail";
import AllVendor from "./components/vendor/AllVendor";
import BookingPayment from "./components/booking/BookingPayment";
import CategoryProduct from "./components/category/CategoryProduct";
import RegisterBusiness from "./components/register/RegisterBusiness";
import Shops from "./components/shop/Shops";
import ManageDashboard from "./components/dashboard/ManageDashboard";
import YourAccount from "./components/userprofile/account/YourAccount";
import ReferralProgram from "./components/userprofile/referralprogram/ReferralProgram";
import Filemanager from "./components/userprofile/filemanage/Filemanager";
import Documents from "./components/userprofile/document/Documents";
import Chatting from "./components/userprofile/chat/Chatting";
import Payments from "./components/userprofile/payment/Payments";
import Gift from "./components/gift/Gift";
import GetGift from "./components/gift/getGift/GetGift";
import UnusedBenefits from "./components/unusedBenefits/UnusedBenefits";
import Header from "./components/header/Header";
import Contact from "./components/sign/Contact";
import Secure from "./components/sign/Secure";
import {
  getGeo,
  getMerchants,
  getProductCategories,
  getUser,
  setSidebarState,
} from "./redux/actions-exporter";
import Success from "./components/cart/success";
import Error from "./components/cart/error";
import ServiceWorkerWrapper from "./components/ServiceWorkerWrapper";
import InstallPWA from "./components/InstallPWA";
import Sales from "./components/sales/Sales";
import sendUrlStatistics from "./redux/API/statistics/statistics.action";
import "./App.css";
import max from "./assets/images/elad.png";
import ms from "./assets/images/logo_cellcoin.png";
import Management from "./components/wallets/Management";

function App(props) {
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [newMoney, setNewMoney] = useState(0);
  const [oldMoney, setOldMoney] = useState(0);
  const [vendors, setVendors] = useState();
  const dispatch = useDispatch();
  const { isChatOpen } = useSelector((state) => state.chat);
  const [phone, setPhone] = useState(false);
  const geo = useSelector((state) => state.user.geo);

  useEffect(() => {
    if (pathname === "/allvendors") {
      dispatch(getMerchants("load", geo.longitude, geo.latitude, 100000000));
    } else {
      dispatch(
        getMerchants("benefit", geo.longitude, geo.latitude, 10000000000)
      );
    }
  }, [dispatch, pathname]);

  useEffect(() => {
    dispatch(getProductCategories());
  }, [dispatch]);

  // confirm back
  if (pathname === "/wallet") {
    window.history.pushState(null, "", window.location.href);
    // window.onpopstate = function () {
    //   window.history.pushState(null, "", window.location.href);
    // };
  }
  // const navigate = useNavigate();
  // const handleBack = (event) => {
  // event.preventDefault();
  // event.returnValue = "";
  // window.history.go(1)
  // navigate(location);
  // };
  // useEffect(() => {
  //   window.addEventListener("popstate", handleBack);
  //   return () => {
  //     window.removeEventListener("popstate", handleBack);
  //   };
  // }, []); // eslint-disable-line

  useEffect(() => {
    dispatch(getUser());

    document.addEventListener("b24chat.widget.logout", () => {
      dispatch(setSidebarState(false));
      dispatch(getUser());
    });
    document.addEventListener("b24chat.widget.close", () =>
      dispatch(setSidebarState(false))
    );
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    sendUrlStatistics(window?.location?.href);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  function success(pos) {
    dispatch(
      getGeo({
        latitude: pos.coords.latitude,
        longitude: pos.coords.longitude,
      })
    );
  }

  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "denied") {
          }
        });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);
  return (
    <>
      {loading && window.location.pathname === "/" && (
        <div className="loader">
          <img alt="" src={ms} className="mainImg " />
          {/* <CircularProgress
            size={100}
            style={{ color: "var(--primary)", margin: "30% 0" }}
          /> */}

          <div className="poweredBlock">
            <div className="loaderText">
              Powered By
            </div>
            <img alt="" src={max} className="subImg" />
          </div>
        </div>
      )}
      <Routes>
        <Route path="/privacy" element={<Secure fromRoute={true} />} />
        <Route path="/contacts" element={<Contact fromRoute={true} />} />
      </Routes>
      {!(window.location.pathname === "/privacy") &&
        !(window.location.pathname === "/contacts") && (
          <div className={cx("PageDad", { PageDad_loading: loading })}>
            <div className="Page">
              <div className={isChatOpen ? "activeChat" : ""}>
                <ServiceWorkerWrapper />
                <InstallPWA icon={false} />
                <ToastContainer style={{ textAlign: "center" }} />
                {/* <BrowserRouter> */}
                <Header reduce={oldMoney - newMoney} />
                <Routes>
                  {/* <Route path="/header" element={<Header />} /> */}
                  <Route path="/" element={<Dashboard />} />
                  {/* <Route path="/footer" element={<Footer />} /> */}
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/forgotPassword" element={<ForgotPassword />} />
                  <Route path="/userprofile" element={<UserProfile />} />
                  <Route
                    path="/categorydetails"
                    element={<CategoryDetails />}
                  />
                  <Route path="/category/:id" element={<CategoryProduct />} />
                  <Route
                    path="/networks/:id"
                    element={<ProductNetworks {...props} />}
                  />{" "}
                  <Route
                    path="/product/:id"
                    element={<ProductDetails {...props} />}
                  />
                  <Route
                    path="/vendor/:id"
                    element={<VendorsDetails {...props} />}
                  />
                  <Route path="/sales" element={<Sales />} />
                  <Route path="/cart" element={<Cart />} />
                  <Route
                    path="/wallet"
                    element={<Wallets setVendors={setVendors} />}
                  />
                  <Route path="/manage" element={<Management />} />
                  <Route path="/payment" element={<Payment />} />
                  <Route path="/loadmoney" element={<LoadMoney />} />
                  <Route
                    path="/loadcredit"
                    element={<LoadMoney credit={true} />}
                  />
                  <Route path="/creaditcard" element={<CreaditCard />} />
                  <Route path="/shops" element={<Shops />} />
                  <Route path="/categorylist" element={<CategoryList />} />
                  <Route path="/map" element={<Map />} />
                  {/* <Route path="/map" element={<TestMap />} /> */}
                  <Route path="/paymenthistory" element={<PaymentHistory />} />
                  {/* <Route path="/menu" element={<Menu />} /> */}
                  <Route path="/checkout" element={<Checkout />} />
                  <Route
                    path="/paymentcardhistory"
                    element={<PaymentCardHistory />}
                  />
                  <Route path="/company" element={<Company />} />
                  <Route path="/product" element={<Product />} />
                  <Route path="/bookingcart" element={<BookingCart />} />
                  <Route
                    path="/bookingcartdetail"
                    element={<BookingCartDetail />}
                  />
                  <Route
                    path="/allvendors"
                    element={<AllVendor vendors={vendors} />}
                  />
                  <Route path="/bookingpayment" element={<BookingPayment />} />
                  <Route path="/gift/*" element={<Gift />} />
                  <Route path="/getgift" element={<GetGift />} />
                  {/*<Route path="/chatbot" element={<ChatBot />} />*/}
                  <Route
                    path="/registerbusiness"
                    element={<RegisterBusiness />}
                  />
                  <Route
                    path="/managedashboard"
                    element={<ManageDashboard />}
                  />
                  <Route path="/youraccount" element={<YourAccount />} />
                  <Route
                    path="/referralprogram"
                    element={<ReferralProgram />}
                  />
                  <Route path="/filemanager" element={<Filemanager />} />
                  <Route path="/documents" element={<Documents />} />
                  <Route path="/chatting" element={<Chatting />} />
                  <Route path="/payments" element={<Payments />} />
                  <Route path="/pay" element={<Pay />} />
                  <Route
                    path="/businesspayment"
                    element={
                      <BusinessPayment
                        newMoney={newMoney}
                        oldMoney={oldMoney}
                        setNewMoney={setNewMoney}
                        setOldMoney={setOldMoney}
                      />
                    }
                  />
                  <Route path="/paymentapprove" element={<PaymentApprove />} />
                  <Route path="/unusedbenefits" element={<UnusedBenefits />} />
                  <Route
                    path={"/checkout/success/:orderId"}
                    element={<Success />}
                    exact
                  />
                  <Route
                    path={"/checkout/error/:orderId"}
                    element={<Error />}
                  />
                </Routes>
                <Footer setPhone={setPhone} />
                {/* </BrowserRouter> */}
              </div>
            </div>
          </div>
        )}
    </>
  );
}

export default App;
