import * as React from "react";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import logo from "../../assets/images/logo_cellcoin.png";
import "./rules.css";
import { Box, Container } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const theme = createTheme({
  direction: "rtl",
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export default function Secure({ setItem, fromRoute = false }) {
  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Container
          component="main"
          maxWidth="xs"
          style={fromRoute ? { paddingTop: 20 } : {}}
        >
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mx: "auto",
              background: "var(--secondary)",
              width: "66%",
              borderRadius: "50%",
              aspectRatio: "3/2",
              boxShadow: "0px 0px 20px 0px rgb(42 134 159 / 10%)",
            }}
          >
            <img
              src={logo}
              style={{
                objectFit: "contain",
                width: "80%",
              }}
              alt="win-logo"
            />
            {!fromRoute && (
              <span
                style={{ marginRight: 20 }}
                className="backIcon isLink"
                onClick={() => setItem("")}
              >
                <ArrowForwardIosIcon />
              </span>
            )}
          </Box>
          <h5
            style={{
              textAlign: "center",
              marginTop: "25px",
            }}
          >
            <b>רכישה מאובטחת</b>
          </h5>
          <div className="rules">
            <ul>
              <li>
                <h5>אתר המועדון - מהווה סביבת רכישה נוחה, מוגנת ובטוחה !</h5>
                המידע העובר באתר מאובטח על יד פרוטוקול האבטחה העולמי SSL, ומאושר
                לשימוש על ידי ספק עולמי מורשה. מערכת הצפנת מידע בפרוטוקול SSL
                מבטיחה כי בעת ביצוע פעולות רכישה או כל פעולה אחרת הכרוכה בשימוש
                במידע אישי, מפעילה מערכת האתר שיגרת אבטחה המאבטחת את התקשורת
                משני הצדדים, הן מצד הלקוח והן מצד המערכת.
              </li>
              <li>
                <h5>מהו פרוטוקול SSL ?</h5>
                מערכת האבטחה לביצוע העברת נתונים מאובטחים באתר. בעת שציור המנעול
                הסגור מוצג בתחתית המסך, העברת המידע בין השרת למחשבכם וחזרה
                מתבצעת באופן מוצפן ומוגן ע"י פרוטוקול SSL. פרוטוקול זה מצפין את
                כל הנתונים העוברים בערוץ והופך אותם לרצף חסר משמעות של סימנים,
                אותיות ומספרים לכל צופה מהצד. בדרך זו גם מתבצע אימות של כרטיס
                האשראי מול חברת כרטיסי האשראי, ומתקבל אישור בדבר נכונות פרטיו של
                הלקוח.
              </li>
              <li>
                <h5>אבטחת המידע על שרתי המועדון </h5>
                האתר מופעל מחוות שרתי האינטרנט המתקדמת של חברת AWS. השרתים
                מצויים מאחוריי firewall ואמצעי האבטחה העדכניים והחדישים ביותר
                הקיימים בשוק. כל הנתונים האישיים המוזנים ע"י לקוחות האתר נשמרים
                בצורה מוצפנת במערכת על גבי שרת מאובטח ונפרד שאינו ניתן לגישה של
                גולשי אינטרנט אחרים.
              </li>
              <li>
                <h5>אימות פרטי כרטיסי האשראי</h5>
                בכל רכישה מתבצע אימות של כרטיס האשראי מול חברת כרטיסי האשראי,
                ומקבלים את אישורם על נכונות פרטיו של הלקוח.
              </li>
              <li>
                <h5>אחריות מצד חברות כרטיסי האשראי</h5>
                לפי חוק כרטיסי האשראי, כאשר נעשה בכרטיס אשראי "שימוש לרעה",
                כלומר שימוש בידי מי שאינו זכאי לכך, לא יהיה אחראי בעל הכרטיס
                בשום מקרה על פעולה זו, והחברה המנפיקה את הכרטיס תהיה חייבת
                להחזיר לבעל הכרטיס את הסכום תוך 30 יום.
              </li>
            </ul>
          </div>
        </Container>
      </ThemeProvider>
    </CacheProvider>
  );
}
