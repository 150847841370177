import React from 'react'
import { GiTwoCoins } from "react-icons/gi";
// import widgetHelper from "../../../helpers/widget";
// import {useDispatch} from "react-redux";
// import {openSidebar} from "../../../redux/store/sidebar/sidebar.action";

const Coupon = ({ title, subTitle, img, color, bg, desc }) => {
    // const dispatch = useDispatch();
    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
      };
    return (
        <div
            className="caseback-box"
            style={{
                backgroundColor: bg,
                color: color
                // backgroundColor,
                // width: '363px',
                // ...style
            }}
        >
            <div className="caseback-boxInner">
                <div className='caseback-text'>
                    <h5 className="casebackBox-Title">
                        {title}
                    </h5>
                    <h5 className="casebackBox-offer">
                        {/* {discountValue ? (<><span>{discountValue}{isTypePercent} </span> {t("Discount")}</>) : (<p>להצטרף למועדון CellCoin+</p>)} */}
                    </h5>
                    <h6 className="casebackBox-subTitle">
                        {subTitle}
                    </h6>
                    {desc ? (<p>{desc}<GiTwoCoins /></p>) : ("")}
                    <button onClick={()=>openInNewTab('https://sl.max.co.il/d/skxW')}>להזמנה</button>
                </div>
                <div className='caseback-image'>
                    <img src={img} alt="papa jones" />
                </div>

            </div>
        </div>
    );
}

export default Coupon