import React, { useEffect, useState } from "react";
import { FiHome } from "react-icons/fi";
import { AiFillPhone, AiOutlineTags } from "react-icons/ai";
import { BsFillChatFill, BsWallet2 } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import cx from "classnames";
import { BiSupport } from "react-icons/bi";
import Drawer from "@mui/material/Drawer";
import GrayIcon from "./GrayIcon";
import SignUp from "../sign/SignUp";
import SignIn from "../sign/SignIn";
import Menu from "../sign/Menu";
import Rules from "../sign/Rules";
import Cancel from "../sign/Cancel";
import Contact from "../sign/Contact";
import Secure from "../sign/Secure";
import ForgotPassword from "../sign/ForgotPassword";
import { openSidebar, setSidebarState } from "../../redux/actions-exporter";
import widgetHelper from "../../helpers/widget";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import "./Footer.css";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

const containerArr = [
  {
    img: <FiHome />,
    link: "/",
    title: "ראשי",
  },
  {
    img: <AiOutlineTags />,
    link: "/unusedbenefits",
    title: "השוברים שלי",
  },

  {
    img: <BsWallet2 />,
    link: "/wallet",
    title: "ארנק",
  },
];

const Footer = ({ setPhone }) => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const isOpenSidebar = useSelector((state) => state.sidebar.isOpen);
  const user = useSelector((state) => state.user.user);
  const [support, setSupport] = useState(false);
  const [officeHours, setOfficeHours] = useState(false);
  const [signUp, setSignUp] = useState("signin");
  const [item, setItem] = useState("");
  useEffect(() => {
    setPhone(support);
  }, [support]);
  useEffect(() => {
    if (item === "ניהול כרטיסי אשראי") {
      dispatch(setSidebarState(false));
      nav("/manage");
      setItem("");
    }
  }, [item, dispatch, nav]);
  function SwitchComponent({ route }) {
    switch (route) {
      case "תקנון":
        return <Rules setItem={setItem} />;
      case "מדיניות ביטול עסקה":
        return <Cancel setItem={setItem} />;
      case "יצירת קשר":
        return <Contact setItem={setItem} />;
      case "רכישה מאובטחת":
        return <Secure setItem={setItem} />;
      default:
        return <Menu setSignUp={setSignUp} setItem={setItem} />;
    }
  }
  return (
    <>
      <div>
        <footer className="footer">
          <div
            className={cx(
              "customizer border-left-blue-grey border-left-lighten-4",
              isOpenSidebar ? "open" : "d-none d-md-block"
            )}
          >
            <div
              id="widget-52e71f7f6254c25b6801e462c69f42580ea008aa"
              style={{ display: !user ? "none" : "block" }}
              data-view="slide"
            />
            <div
              className="chatCloseIcon"
              onClick={() => dispatch(setSidebarState(!isOpenSidebar))}
            >
              <ClearIcon />
            </div>
            {!user && (
              <>
                {signUp === "signup" ? (
                  <SignUp setSignUp={setSignUp} />
                ) : signUp === "signin" ? (
                  <SignIn setSignUp={setSignUp} />
                ) : signUp === "forgot" ? (
                  <ForgotPassword setSignUp={setSignUp} />
                ) : (
                  ""
                )}
              </>
            )}
            {user && <SwitchComponent route={item} />}
          </div>
          <div className="sosAndGray">
            <div className="grayIcons">
              {containerArr.map((el, i) => {
                return (
                  <GrayIcon
                    img={el.img}
                    i={i}
                    key={i}
                    link={el.link}
                    title={el.title}
                  />
                );
              })}
            </div>
          </div>

          <div
            className="mainChat"
            onClick={() => {
              // if (moment().tz("Israel").hour() >= 16) setOfficeHours(true);
              // else setSupport(true);
              setOfficeHours(true) // during the war
            }}
          >
            <BiSupport size={40} />
            <div className="grayIconTitle white">לנציג</div>
          </div>
        </footer>
      </div>
      <Drawer
        PaperProps={{
          sx: {
            width: "fit-content",
            maxWidth: "400px",
            left: "calc(50vw - 130px)",
            top: "25%",
            height: "auto",
            margin: "auto",
            borderRadius: "10px",
            padding: "10px",
          },
        }}
        open={officeHours}
        onClose={() => setOfficeHours(false)}
      >
        <IconButton
          sx={{
            zIndex: "11",
            position: "absolute",
            justifySelf: "left",
          }}
          onClick={() => setOfficeHours(false)}
        >
          <CloseIcon />
        </IconButton>
        <div style={{ textAlign: "center", height: "100%" }}>
          <h5 style={{ color: "var(--primary)" }}>שלום רב</h5>
          <br />
          <p style={{ color: "var(--primary)" }}>לאור המצב מוקד השירות סגור.</p>
          <p style={{ color: "var(--primary)" }}>
            ניתן לפנות אלינו במייל :{" "}
            <a href="mailto:office@benefits.co.il">office@benefits.co.il</a>
          </p>
          <p style={{ color: "var(--primary)" }}>ונחזור תוך יום עסקים.</p>
          <p style={{ color: "var(--primary)" }}>
            בפנייתכם נא לציין מס' טלפון.
          </p>
          <p style={{ color: "var(--primary)" }}>תודה רבה.</p>
          <p style={{ color: "var(--primary)" }}>
            <b>ביחד ננצח</b>
          </p>
        </div>
      </Drawer>
      <Drawer
        className="callOrChat"
        PaperProps={{
          sx: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "300px",
            bottom: "90px",
            height: "120px",
            margin: "auto",
            borderRadius: "60px",
            padding: "10px",
          },
        }}
        open={support}
        onClose={() => {
          setSupport(false);
        }}
        anchor="bottom"
      >
        <a
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            height: "100%",
            alignItems: "center",
            color: "black",
          }}
          href="tel:089966241"
        >
          <AiFillPhone fill="var(--primary)" size={50} />
          <h6>שיחה</h6>
        </a>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            height: "100%",
            alignItems: "center",
          }}
          onClick={() => {
            setSupport(false);
            widgetHelper.openMenu(() => dispatch(openSidebar()));
          }}
        >
          <BsFillChatFill fill="var(--primary)" size={50} />
          <h6>צ'ט</h6>
        </div>
        <IconButton
          sx={{
            zIndex: "11",
            position: "absolute",
            left: 5,
          }}
          onClick={() => setSupport(false)}
        >
          <CloseIcon />
        </IconButton>
      </Drawer>
    </>
  );
};

export default Footer;
