import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, MenuItem } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";

import Header from "../header/Header";
import {
  fastCheckout,
  getCards,
  addCard,
  removeCard,
} from "../../redux/actions-exporter";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import "../cart/checkout.css";
import Debitcard from "../../assets/images/debitcard1.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme = createTheme({
  direction: "rtl",
});
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});
function Management() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 20 }, (_, i) =>
    (currentYear + i).toString().slice(-2)
  );
  const [card, setCard] = useState({});
  const [showcard, setShowCard] = useState(false);
  const cards = useSelector((state) => state.card.cards);
  const [paymentMethod, setPaymentMethod] = useState("pelecard-iframe");

  const handleMonthChange = (e) => {
    let month = e.target.value;

    if (month === "") {
      setSelectedMonth("");
      setCard({ ...card, expirationDate: "" + selectedYear });
      return;
    }

    month = parseInt(month, 10);
    let isValid = month >= 1 && month <= 12;
    if (!isValid) {
      return;
    }

    month = month.toString();
    if (month.length === 1) {
      month = "0" + month;
    }

    setSelectedMonth(month);
    setCard({ ...card, expirationDate: month + selectedYear });
  };

  const handleYearChange = (e) => {
    let year = e.target.value;
    year = year.toString();
    if (year.length > 2) {
      year = year.slice(-2);
    }
    setSelectedYear(year);
    setCard({ ...card, expirationDate: selectedMonth + year });
  };

  useEffect(() => {
    dispatch(getCards());
  }, []); // eslint-disable-line
  useEffect(() => {
    if (cards.length) setPaymentMethod(cards[0]?.id);
    else setPaymentMethod("pelecard-iframe");
  }, [cards]); // eslint-disable-line
  const onCheckout = () => {
    setCard({});
    dispatch(
      fastCheckout(
        {
          ...location?.state,
          cardId: paymentMethod === "pelecard-iframe" ? null : paymentMethod,
        },
        (data) => {
          const { orderId } = data;
          if (paymentMethod === "pelecard-iframe") {
            const { paymentURL } = data;
            navigate("/pay", {
              state: { paymentURL, orderId },
            });
          } else {
            navigate(
              `/checkout/${data?.isPaid ? "success" : "error"}/${orderId}`,
              { state: { error: data?.message || "" } }
            );
          }
        }
      )
    );
  };
  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <div>
          {/* <Header /> */}
          <div className="pageTemplate">
            <div className="container">
              {!showcard ? (
                <div>
                  <div className="templateHeading">
                    <span className="backIcon isLink">
                      <ArrowBackIcon
                        onClick={() => {
                          navigate("/");
                        }}
                      />
                    </span>
                    <h1 className="templateTitle">
                      {t("Credit card management")}
                    </h1>
                  </div>
                  <div className="checkoutBlocks">
                    {cards.length > 0 && (
                      <h5 className="checkoutBlocks-title">
                        {" "}
                        {t("Credit card list")}
                      </h5>
                    )}
                    <div className="row justify-content-center">
                      {cards.map((card) => (
                        <div className="col-lg-12" onClick={() => {}}>
                          <div
                            className={cx("PaymentCards", {
                              PaymentCards__selected: paymentMethod === card.id,
                            })}
                          >
                            <span className="PaymentCards-no">
                              {card.number}
                            </span>
                            <DeleteIcon
                              className="PaymentCards__delete"
                              onClick={() => dispatch(removeCard(card.id))}
                            />
                          </div>
                        </div>
                      ))}
                      {cards.length === 0 && (
                        <div className="col-lg-12 text-center">
                          <Button
                            variant="contained"
                            onClick={() => setShowCard(true)}
                            className="LinkBtn"
                          >
                            <AddIcon />
                            {t("addNewCard")}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <>
                    <div className="templateHeading">
                      <span className="backIcon">
                        <ArrowBackIcon onClick={() => setShowCard(false)} />
                      </span>
                      <h1 className="templateTitle">{t("addNewCard")}</h1>
                    </div>
                    <div className="checkoutBlocks">
                      <h5 className="checkoutBlocks-title text-center">
                        {t("paymentMethod")}
                      </h5>
                      <div
                        className="row"
                        dir="rtl"
                        // style={{ direction: 'ltr !important' }}
                      >
                        <div className="col-lg-12">
                          <div className="cardTextNo">
                            <TextField
                              id="outlined-basic"
                              label={t("cardNumber")}
                              type="tel"
                              variant="outlined"
                              autoComplete="off"
                              onChange={(e) => {
                                setCard({
                                  ...card,
                                  number: e.currentTarget.value,
                                });
                                // const input = e.target.value;
                                // if (input.length <= 19 && /^\d*$/.test(input)) {
                                //   setCard({
                                //     ...card,
                                //     number: input,
                                //   });
                                // }
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="row">
                            <div className="col-6">
                              <TextField
                                id="month-select"
                                label={t("Expiry Month")}
                                placeholder={"MM"}
                                value={selectedMonth}
                                onChange={handleMonthChange}
                                variant="outlined"
                                autoComplete="off"
                                fullWidth
                              ></TextField>
                              <TextField
                                id="outlined-basic"
                                label={t("CVC/CVV")}
                                variant="outlined"
                                autoComplete="off"
                                onChange={(e) =>
                                  setCard({
                                    ...card,
                                    cvv2: e.currentTarget.value,
                                  })
                                }
                              />
                            </div>
                            <div className="col-6">
                              <TextField
                                id="year-select"
                                placeholder={"YY"}
                                label={t("Expiry Year")}
                                value={selectedYear}
                                onChange={handleYearChange}
                                variant="outlined"
                                autoComplete="off"
                                fullWidth
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <TextField
                            id="outlined-basic"
                            label={t("cardName")}
                            variant="outlined"
                            autoComplete="off"
                            onChange={(e) =>
                              setCard({
                                ...card,
                                name: e.currentTarget.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-lg-12">
                          <TextField
                            id="outlined-basic"
                            label={t("id")}
                            variant="outlined"
                            autoComplete="off"
                            onChange={(e) =>
                              setCard({
                                ...card,
                                idCard: e.currentTarget.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="checkoutFooterBtn">
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setShowCard(false);
                            setCard({});
                            dispatch(addCard(card));
                          }}
                        >
                          {t("save")}
                        </Button>
                      </div>
                    </div>
                  </>
                </>
              )}
            </div>
          </div>
        </div>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default Management;
