import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MdLocationOn } from "react-icons/md";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import { GiTwoCoins } from "react-icons/gi";
import { Button } from "@material-ui/core";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Header from "../header/Header";
import { getProductCategories, getUnused } from "../../redux/actions-exporter";

import couponCover from "../../assets/images/couponCover.jpg";

export default function UnusedBenefits() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]); // eslint-disable-line
  const [filterProducts, setFilterProducts] = useState([]);
  const unused = useSelector((state) => state.order?.unused);
  const [code, setCode] = useState([]);

  useEffect(() => {
    dispatch(getUnused());
  }, [dispatch]); // eslint-disable-line
  useEffect(() => {
    if (unused.length > 0) {
      let newUnused = unused
        ?.filter((item) => item.type === "product")
        .map((item) => ({ ...item, createdAt: new Date(item.createdAt) }));
      newUnused = newUnused.sort((a, b) => b.createdAt - a.createdAt);
      setFilterProducts(newUnused);
    }
  }, [unused]);

  return (
    <div>
      {/* <Header /> */}
      <div className="pageTemplate">
        <div className="container">
          <div
            className="PageBgHeading"
            style={{ backgroundImage: `url(${couponCover})` }}
          >
            <h5 className="PageBgHeading-title">{t("not used yet")}</h5>
          </div>

          <ul className="product-Module">
            {filterProducts.length > 0
              ? filterProducts.map((product) => {
                  return (
                    <>
                      <li
                        className="product-Module__list isLink"
                        // onClick={() => {
                        //   navigate(`/product/${product?.product.id}`, {
                        //     state: { id: product?.product.id },
                        //   });
                        // }}
                      >
                        <div
                          className="product-box"
                          style={{ direction: "ltr" }}
                        >
                          <div
                            className="product-box__img"
                            style={{
                              backgroundImage: `url(${product?.product?.image})`,
                            }}
                          >
                            <div className="product-box__likeBtn">
                              <FavoriteBorderOutlinedIcon />
                            </div>
                          </div>
                          <div className="product-box__info high">
                            <div className="product-box__infoTop">
                              <div className="product-box__titleAndLoc">
                                <h6 className="product-box__brand">
                                  {product?.product?.title?.length < 35
                                    ? product?.product?.title
                                    : product?.product?.title?.slice(0, 34) +
                                      "..."}
                                </h6>
                                {/* <div className="product-box__Loc">
                                <MdLocationOn />
                                <h6 className="product-box__brand product-box__LocH">
                                  הרצליה
                                </h6>
                              </div> */}
                              </div>
                              <span className="product-box__price">
                                <s>₪{product?.product?.price}</s>
                                &nbsp;
                              </span>
                            </div>
                            <div className="product-box__priceDiv">
                              <span className="product-box__price bold">
                                {product?.product.priceB2B} {"₪ + "}
                                {product?.product.price -
                                  product?.product.priceB2B}
                                <GiTwoCoins />
                              </span>
                            </div>
                            <Button
                              sx={{ my: 1 }}
                              className="addcart_btn"
                              onClick={() => setCode([product])}
                            >
                              <span>הצג</span>
                            </Button>
                          </div>
                        </div>
                      </li>
                    </>
                  );
                })
              : t("No products")}
          </ul>
        </div>
      </div>
      <Drawer
        className="infoWrapper"
        PaperProps={{
          sx: {
            width: "80%",
            maxWidth: "350px",
            left: "10%",
            top: "30%",
            height: "40%",
            margin: "auto",
            borderRadius: "10px",
            padding: "10px",
            zIndex: "10000",
          },
        }}
        open={code.length > 0}
        onClose={() => setCode([])}
      >
        <IconButton
          sx={{
            justifyContent: "end",
            p: 0,
            width: "25px",
          }}
          onClick={() => setCode([])}
        >
          <CloseIcon />
        </IconButton>
        <div className="container">
          <div className="paymentApproveContent">
            <div className="paymentApproveText">
              <h5 className="pelecard-page__title">
                {code[0]?.product.title}{" "}
              </h5>
              <hr />
              <h5 className="paymentApproveLine1">
                קוד מולטיפס: {code[0]?.cardNumber}
              </h5>
              <h5 className="pelecard-page__info">(להצגה בקופה)</h5>
              {code[0]?.cardPin}
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
