import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import nis from "../../assets/images/nis.png";

import { GiTwoCoins } from "react-icons/gi";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { getProductCategories } from "../../redux/actions-exporter";
import CouponsList from "../couponsList";
import HotBenefitList from "./HotBenefitList";

import "./dashboard.css";

export default function Dashboard() {
  window.history.replaceState({}, document.title);
  const [products, setProducts] = useState([]);
  const [filterProducts, setFilterProducts] = useState([]);
  // eslint-disable-next-line
  const merchants = useSelector((state) => state.merchant.merchants);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const categories = useSelector(
    (state) => state.productCategories.productCategories
  );
  const user = useSelector((state) => state.user.user);
  useEffect(() => {
    dispatch(getProductCategories());
  }, [dispatch]); // eslint-disable-line

  const handleChange = (newValue) => {
    if (newValue === "all") {
      setFilterProducts(products);
    } else {
      let productByCategoryById = [];
      categories.map((category) => {
        if (category.id === newValue) {
          category.products.map((product) => {
            if (product.price > 0) productByCategoryById.push(product);
            return product;
          });
        }
        return category;
      });
      setFilterProducts(productByCategoryById);
    }
  };

  useEffect(() => {
    if (categories?.length) {
      const prepareProduct = categories.reduce(
        (previous, current) => [
          ...previous,
          ...current.products.map((product) => ({
            ...product,
            categoryId: current.id,
            categoryName: current.title,
          })),
        ],
        []
      );
      setProducts(prepareProduct);
      const uniques = [
        ...new Map(prepareProduct.map((v) => [v.id, v])).values(),
      ];
      const onlyPrice = uniques.filter((product) => product.price > 0);
      setFilterProducts(onlyPrice);
    }
  }, [categories]);
  return (
    <>
      <div className="dashboard-tamplate">
        {/* <Header /> */}

        <div className="container">
          <div className="block-slider">
            <div className="module-heading">{!user && <CouponsList />}</div>

            <HotBenefitList
              isAllVendors={false}
              storesText={"stores"}
              categories={categories}
            />
          </div>
          <div className="products-title">
            <h6 className="module-heading__title">כל ההטבות</h6>
          </div>
          <ScrollingCarousel>
            <Tabs
              defaultSelectedIndex={0}
              className="categoriesSliderTabs"
              onChange={handleChange}
            >
              <div
                className="CategoryTabDiv"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleChange("all");
                }}
              >
                <img alt="" src={nis} className="tabImg" />

                <Tab value="all" label={t("all")}>
                  All
                </Tab>
              </div>
              {categories?.length > 0 &&
                categories
                  ?.filter(
                    (category) =>
                      !category.parent && category?.products?.length > 0
                  )
                  .map((category, i) => (
                    <div
                      className="CategoryTabDiv"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleChange(category.id);
                      }}
                    >
                      <img alt="" src={category.image} className="tabImg" />
                      <Tab value={category.id} label={t(category?.title)}>
                        {t(category?.title)}
                      </Tab>
                    </div>
                  ))}
            </Tabs>
          </ScrollingCarousel>

          <div className="product-block">
            <ul className="product-Module">
              {filterProducts.length > 0
                ? filterProducts
                    .filter(
                      (product) =>
                        !product.title.toLowerCase().includes("mcdonald")
                    ) // remove mcdonalds
                    .sort((a, b) => {
                      const keywordsToEnd = ["גלובל"];
                      const aShouldPushToEnd = keywordsToEnd.some((keyword) =>
                        a.title.includes(keyword)
                      );
                      const bShouldPushToEnd = keywordsToEnd.some((keyword) =>
                        b.title.includes(keyword)
                      );
                      if (aShouldPushToEnd && !bShouldPushToEnd) {
                        return 1;
                      } else if (!aShouldPushToEnd && bShouldPushToEnd) {
                        return -1;
                      } else {
                        return 0;
                      }
                    })
                    .map((product) => (
                      <>
                        <li
                          className="product-Module__list isLink"
                          onClick={() => {
                            navigate(`/networks/${product.id}`, {
                              state: { id: product.id },
                            });
                          }}
                        >
                          <div className="product-box">
                            <div
                              className="product-box__img"
                              style={{
                                backgroundImage: `url(${product.image})`,
                              }}
                            >
                              <div className="product-box__likeBtn">
                                <FavoriteBorderOutlinedIcon
                                  onClick={() => {}}
                                />
                              </div>
                            </div>
                            <div className="product-box__info">
                              <div className="product-box__infoTop">
                                <div className="product-box__titleAndLoc">
                                  <h6 className="product-box__brand">
                                    {product.title}{" "}
                                  </h6>
                                </div>
                                <span className="product-box__price">
                                  ₪{product?.price}
                                  &nbsp;
                                </span>
                              </div>
                              <div className="product-box__priceDiv">
                                <span className="product-box__price bold">
                                  {"בתוספת "}
                                  {Math.floor(product?.price * 0.1)}
                                  <GiTwoCoins />
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>
                      </>
                    ))
                : t("No products")}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
