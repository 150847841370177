import * as React from "react";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import logo from "../../assets/images/logo_cellcoin.png";
import "./rules.css";
import { Box, Container } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const theme = createTheme({
  direction: "rtl",
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export default function Contact({ setItem, fromRoute = false }) {
  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Container
          component="main"
          maxWidth="xs"
          style={fromRoute ? { paddingTop: 20 } : {}}
        >
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mx: "auto",
              background: "var(--secondary)",
              width: "66%",
              borderRadius: "50%",
              aspectRatio: "3/2",
              boxShadow: "0px 0px 20px 0px rgb(42 134 159 / 10%)",
            }}
          >
            <img
              src={logo}
              style={{
                objectFit: "contain",
                width: "80%",
              }}
              alt="win-logo"
            />
            {!fromRoute && (
              <span
                style={{ marginRight: 20 }}
                className="backIcon isLink"
                onClick={() => setItem("")}
              >
                <ArrowForwardIosIcon />
              </span>
            )}
          </Box>
          <h5
            style={{
              textAlign: "center",
              marginTop: "25px",
            }}
          >
            <b>יצירת קשר</b>
          </h5>
          <div className="rules">
            בכל שאלה ניתן לפנות למשרדי אלעד מועדוני צרכנות בע"מ על פי פרטי
            ההתקשרות הבאים:
            <br />
            <br />
            מייל:
            <br />
            <a href="mailto:office@benefits.co.il">office@benefits.co.il</a>
            <br />
            <br />
            כתובת:
            <br />
            מצדה 9, בסר 3, ר"ג
            <br />
            <br />
            מענה טלפוני/צ'אט, דרך האפליקציה:
            <br />
            ימים א'-ה' 09:00-16:00
            <br />
            <br />
            קנייה מהנה.
          </div>
        </Container>
      </ThemeProvider>
    </CacheProvider>
  );
}
