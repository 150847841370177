import React, { useState, useRef, useEffect } from "react";
// import { useDispatch } from "react-redux";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { NumericKeyboard } from "react-numeric-keyboard";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { GiTwoCoins } from "react-icons/gi";
import { useNavigate, useLocation } from "react-router-dom";

import Header from "../header/Header";

import "../wallets/BusinessPayment.css";

export default function BusinessPayment({
  // credit = false,
  newMoney,
  oldMoney,
  setNewMoney,
  setOldMoney,
}) {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  // const [amountToPay, setAmountToPay] = useState(0);
  const location = useLocation();
  const onKey = ({ value, name }) => {
    // setAmountToPay(value ? value : 0);
    setNewMoney(value ? value * 0.88 : 0);
    setOldMoney(value ? value : 0);
  };

  const inputElement = useRef(null);
  // useEffect(() => {
  //   !amountToPay && setNewMoney(0)
  // }, [amountToPay]);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  useEffect(() => {
    //reset 'history'
    setOldMoney(0);
    setNewMoney(0);
  }, []);

  return (
    <div>
      {/* <Header  isFull={false} /> */}
      <div className="loadMoneyTemplate">
        {/* {location?.state && (
          <h4 style={{ textAlign: "center" }}>
            {location?.state?.vendordetails?.title}
          </h4>
        )} */}
        <div className="container">
          <div className="templateHeading">
            <h1 className="templateTitle">
              {location?.state
                ? "תשלום ב" + location?.state?.vendordetails?.title
                : t("payment at the business")}
            </h1>
            <span
              className="backIcon isLink"
              onClick={() => {
                navigate("/wallet");
              }}
            >
              <ArrowForwardIosIcon />
            </span>
          </div>

          <div className="loadMoneyCard">
            <div className="loadMoneyCard-price">
              {/* <input
                ref={inputElement}
                type="number"
                step="1"
                min="0.00"
              onChange={onChange}
              /> */}
              <h2 ref={inputElement}>
                {/* {amountToPay} */}
                {oldMoney}
              </h2>
            </div>
            <div className="arrowWrapper">
              <ArrowDownwardIcon className="arrow" />
            </div>
            <h3 className="loadMoneyCard-text">
              {t("payment amount after take")}{" "}
              {(oldMoney - newMoney)?.toFixed(2) || 0}
              <GiTwoCoins />
              {/* {t("shuk coins")} */}
            </h3>
            <h1 className="loadMoneyCard-price">{newMoney?.toFixed(2)}</h1>
            <div className="key">
              <NumericKeyboard
                backSpaceIcon={<BackspaceIcon fontSizeSmall />}
                leftIcon
                isOpen={true}
                onChange={onKey}
                containerClassName="key"
              />
            </div>
            <div className="loadMoneyCard-btn">
              <Button
                className="blueBtn"
                onClick={() => {
                  navigate("/checkout", {
                    state: {
                      amount: oldMoney,
                      discount: 0.88,
                      type: "load_credit",
                      successURL:
                        process.env.REACT_APP_API_PELECARD_LOADING_URL,
                      errorURL: process.env.REACT_APP_API_PELECARD_LOADING_URL,
                    },
                  });
                }}
              >
                {t("pay")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
