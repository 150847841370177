import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import widgetHelper from "../../helpers/widget";
import { getProductById, openSidebar } from "../../redux/actions-exporter";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as Locate } from "../../assets/icons/locate.svg";

import "../vendor/vendorsDetails.css";

export default function AllVendor({ vendors }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const geo = useSelector((state) => state.user.geo);

  const product = useSelector((state) => state.product.product);
  const merchants = useSelector((state) => state.merchant.merchants);
  const merchantsPending = useSelector(
    (state) => state.merchant.isMerchantsPending
  );
  const [merchantsList, setMerchantsList] = useState([]);
  const [chain, setChain] = useState([]);
  const user = useSelector((state) => state.user.user);
  useEffect(() => {
    dispatch(getProductById(7254, geo.longitude, geo.latitude, 100000000));
  }, [dispatch]);
  useEffect(() => {
    setMerchantsList([]);
  }, [dispatch]); // eslint-disable-line
  useEffect(() => {
    let list;
    if (vendors === "כל המסעדות" && product?.merchants?.length) {
      list = product?.merchants.filter(
        (p) =>
          (p.attributeValues["אפשרות סינון לפי תחומי עניין"]?.every((attr) =>
            ["בתי קפה ומסעדות", "ארוחת בוקר זוגית", "מסעדות שף"].includes(attr)
          ) &&
            !p.title.includes("סינמה") &&
            !p.title.includes("הבימה")) ||
          p.title.includes("קפה קפה")
      );
      const groups = Object.values(
        list?.reduce((acc, curr) => {
          const firstWord = curr.network?.name || curr.title;
          if (!acc[firstWord]) {
            acc[firstWord] = [];
          }

          acc[firstWord].push(curr);
          return acc;
        }, {})
      );
      setMerchantsList(groups.sort((a, b) => b.length - a.length));
    } else {
      list = merchants?.filter(
        (m) =>
          m.attributeValues["אפשרות סינון לפי תחומי עניין"]?.includes(
            "רשתות"
          ) &&
          !m.title.includes("גולדה") &&
          !m.title.includes("מחסני השוק") &&
          !m.title.includes("ניצת הדובדבן") &&
          !m.title.includes("באשר") &&
          !m.title.includes("ספייסז") &&
          !m.title.includes("SPA") &&
          !m.title.includes("ספא")
      );
      const popArr = [
        "Foot Locker",
        "FOX",
        "FOX home",
        "AMERICAN EAGLE",
        "GOLF",
        "GOLF&CO",
        "GOLF&KIDS",
        "H&O",
        "KITAN",
        "MASHBIR",
        "INTIMA",
        "BILLA BONG",
        "MANGO",
        "MAX MORETTI",
        "Timberland",
        "Nautica",
        "aerie",
        "מגה ספורט",
      ];
      list
        .sort((a, b) => (a.title < b.title ? 1 : -1))
        .sort((a) => (popArr.includes(a.title) ? -1 : 0));
      const groups = Object.values(
        list?.reduce((acc, curr) => {
          const networkName = curr.network?.name;
          if (!networkName) acc[curr.title] = curr;
          else if (!acc[networkName]) {
            acc[networkName] = { ...curr, title: curr.network.name };
          }
          return acc;
        }, {})
      );
      setMerchantsList(groups.sort((a, b) => b.length - a.length));
    }
  }, [merchants, product]); //eslint-disable-line

  return (
    <div>
      <div className="pageTemplate">
        <div className="container">
          <div className="vendorAllListBlock">
            <h6 className="module-heading__title">{vendors}</h6>
            <ul className="categoryList">
              {merchantsList?.length > 0 ? (
                merchantsList?.map((item) => (
                  <>
                    {vendors === "כל המסעדות" ? (
                      <li
                        className="categoryList__block"
                        onClick={() => {
                          // if (item.length === 1) {
                          if (item.length === 0) {
                            if (!user) {
                              widgetHelper.login(
                                () => dispatch(openSidebar()),
                                () => navigate("/login")
                              );
                            } else {
                              navigate("/businesspayment", {
                                state: { vendordetails: item[0] },
                              });
                            }
                          } else if (!chain.length) {
                            setChain(item);
                          }
                        }}
                      >
                        <div className="category-box__img">
                          <img
                            src={item[0]?.image || "#"}
                            className="img-fluid"
                            alt="My Awesome"
                          />
                        </div>
                        {/* {item.length > 1 ? ( */}
                        {item.length > 0 ? (
                          <>
                            <h6 className="category-box__title up">
                              {item[0]?.network?.name || item[0]?.title}
                            </h6>
                          </>
                        ) : (
                          <h6 className="category-box__title">
                            {item[0]?.title.length < 20
                              ? item[0]?.title
                              : item[0]?.title.slice(0, 19) + "..."}
                          </h6>
                        )}

                        {chain === item ? (
                          <div className="chains">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                fontSize: "larger",
                                fontWeight: "bold",
                                padding: "10px 0",
                              }}
                            >
                              {chain.length === 1 ? "סניף " : "סניפי "}
                              {chain[0].network?.name || chain[0].title}
                              <IconButton
                                sx={{
                                  justifyContent: "end",
                                  p: 0,
                                }}
                                onClick={() => setChain([])}
                              >
                                <CloseIcon />
                              </IconButton>
                            </div>
                            {chain[0].asterisks[0]?.description
                              ?.split(/(?= -)/)
                              .map((a) => (
                                <>
                                  {a}
                                  <br />
                                </>
                              ))}
                            <br />

                            <div
                              style={{
                                overflowY: "scroll",
                              }}
                            >
                              {chain
                                ?.sort(
                                  (a, b) =>
                                    Number(a.distance) - Number(b.distance)
                                )
                                .map((branch, i) => (
                                  <>
                                    {i !== 0 && (
                                      <hr
                                        style={{
                                          margin: "5px",
                                          borderColor: "#eee",
                                        }}
                                      />
                                    )}
                                    <di
                                      className="menuSearchItem"
                                      onClick={() => {
                                        if (!user) {
                                          widgetHelper.login(
                                            () => dispatch(openSidebar()),
                                            () => navigate("/login")
                                          );
                                        } else {
                                          navigate("/businesspayment", {
                                            state: { vendordetails: branch },
                                          });
                                        }
                                      }}
                                    >
                                      <img
                                        alt=""
                                        className="menuSearchItemImg"
                                        src={branch.image}
                                      />
                                      <div className="menuSearchItemText">
                                        <div>{branch.title}</div>
                                        <div>
                                          {branch.address && (
                                            <div className="menuSearchItemText_sub">
                                              <Locate />
                                              {branch.address}
                                            </div>
                                          )}
                                          <div className="menuSearchItemText_sub sub">
                                            <div
                                              className={`range ${
                                                branch.distance ? "" : "hide"
                                              }`}
                                            >
                                              {branch.distance > 1
                                                ? Math.round(branch.distance) +
                                                  ' ק"מ'
                                                : Math.round(
                                                    (branch.distance -
                                                      Math.floor(
                                                        branch.distance
                                                      )) *
                                                      1000
                                                  ) + " מ'"}
                                            </div>
                                            {branch.kosher !== "לא כשר" && (
                                              <div className="kosher">
                                                {branch.kosher}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </di>
                                  </>
                                ))}
                            </div>
                          </div>
                        ) : null}
                      </li>
                    ) : (
                      <li
                        className="categoryList__block"
                        onClick={() => {
                          if (!user) {
                            widgetHelper.login(
                              () => dispatch(openSidebar()),
                              () => navigate("/login")
                            );
                          } else {
                            navigate("/businesspayment", {
                              state: { vendordetails: item },
                            });
                          }
                        }}
                      >
                        <div className="category-box__img">
                          <img
                            src={item.image}
                            className="img-fluid"
                            alt="My Awesome"
                          />
                        </div>
                        <h6 className="category-box__title">
                          {item.title.length < 30
                            ? item.title
                            : item.title.slice(0, 29) + "..."}
                        </h6>
                      </li>
                    )}
                  </>
                ))
              ) : merchantsPending ? (
                <CircularProgress />
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
