import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Drawer, MenuItem, Select, TextField } from "@material-ui/core";
import PaymentIcon from "@mui/icons-material/Payment";
import { IoIosRestaurant } from "react-icons/io";
import { GiClothes } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { openSidebar, sendMoney } from "../../redux/actions-exporter";
import HelpIcon from "@mui/icons-material/Help";
import widgetHelper from "../../helpers/widget";
import "../wallets/wallets.css";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function Wallets({ setVendors }) {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.user);
  const sendMoneyError = useSelector((state) => state.user.sendMoneyError);
  const [amountType, setAmountType] = useState("money");
  const [amount, setAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [email, setEmail] = useState("");
  const [purpose, setPurpose] = useState("");
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    const storedEntryCount = parseInt(localStorage.getItem("entryCount")) || 0;
    if (storedEntryCount < 3) {
      setShowInfo(true);
      localStorage.setItem("entryCount", String(storedEntryCount + 1));
    }
  }, []);

  const onSend = () => {
    dispatch(
      sendMoney(
        {
          amountType,
          amount,
          email,
          purpose,
        },
        () => toast.success("כסף נשלח בהצלחה"),
        () => toast.error(sendMoneyError.response.data.message)
      )
    );
  };

  useEffect(() => {
    // dispatch(getMerchants("load"));
  }, [dispatch]);

  return (
    <div>
      <Drawer
        PaperProps={{
          sx: {
            width: "fit-content",
            maxWidth: "400px",
            left: "calc(50vw - 200px)", //What the fuck is this? I cba.
            top: "12.5%",
            height: "auto",
            margin: "auto",
            borderRadius: "10px",
            padding: "10px",
          },
        }}
        open={showInfo}
        onClose={() => setShowInfo(false)}
      >
        <IconButton
          sx={{
            zIndex: "11",
            position: "absolute",
            justifySelf: "left",
          }}
          onClick={() => setShowInfo(false)}
        >
          <CloseIcon />
        </IconButton>
        <div className="info">
          <h5>הארנק הדיגיטלי</h5>
          <p className="marginP">ברוכים הבאים לארנק ההטבות הדיגיטלי שלכם.</p>
          <p className="marginP">
            באזור זה תוכלו לשלם בהנחה כנגד ה-cellcoins שצברתם, במגוון רחב של
            עסקים.
          </p>
          <p className="marginP">
            את רשימת העסקים תוכלו למצוא תחת רשימת הרשתות המכבדות.
          </p>
          <p className="marginP">
            <strong>אז איך זה עובד?</strong>
            <br />
            כל שעליכם לעשות הוא ללחוץ על
            <strong> "תשלום בבית העסק" </strong>
            ולהקליד את סכום הקניה או הארוחה שלכם.
          </p>
          <p className="marginP">
            מטבעות ה-CellCoin שניתן לממש בעסקה מחושבות באופן אוטומטי.
          </p>
          <p className="marginP">
            הסכום בו יחוייב כרטיס האשראי שלכם הוא הסכום (לאחר הנחה) שמופיע מתחת
            לסכום העסקה. לאחר ביצוע התשלום, תקבלו קוד, אותו עליכם להציג בקופת
            בית העסק.
          </p>
        </div>
      </Drawer>
      <div className="walletTamplate">
        <div style={{ width: "fit-content" }}>
          <div className="notificationBlock">
            <Button className="dropBtn" onClick={() => setShowInfo(true)}>
              <HelpIcon />
            </Button>
          </div>
        </div>
        <div className="container">
          <div className="walletIcons">
            <div
              className="walletIconsBox isLink"
              style={{ margin: "0 50px" }}
              onClick={() => {
                if (!user) {
                  widgetHelper.login(
                    () => dispatch(openSidebar()),
                    () => navigate("/login")
                  );
                } else {
                  navigate("/businesspayment");
                }
              }}
            >
              <PaymentIcon />
              <h6 className="walletIconsBox-title">
                {t("pay at the buisness")}
              </h6>
            </div>
            <div
              className="walletIconsBox isLink"
              onClick={() => {
                setVendors("כל המסעדות");
                navigate("/allvendors");
              }}
            >
              <IoIosRestaurant />
              <h6 className="walletIconsBox-title">{t("restaurants")}</h6>
            </div>
            <div
              className="walletIconsBox isLink"
              onClick={() => {
                setVendors("רשתות אופנה");
                navigate("/allvendors");
              }}
            >
              <GiClothes />
              <h6 className="walletIconsBox-title">{t("fashion")}</h6>
            </div>
          </div>

          <div className="walletBtns">
            <Button
              className="blueBtn"
              onClick={() => {
                navigate("/paymenthistory");
              }}
            >
              {t("paymentHistory")}
            </Button>
          </div>
          <div>
            {show ? (
              <div className="ContactUsModule">
                <h6 className="ContactUsModule-title"> {t("sendMoney")}</h6>
                <Select
                  labelId="amount-type"
                  id="amount-type"
                  name="select"
                  value={amountType}
                  label={t("Amount type")}
                  style={{ border: 0, direction: "ltr" }}
                  className="inputStyle inputStyle-select"
                  onChange={(e) => {
                    setAmountType(e.target.value);
                    setMaxAmount(user[e.target.value]);
                    setAmount(0);
                  }}
                >
                  <MenuItem value="money">{t("Money")}</MenuItem>
                  <MenuItem value="credit">{t("Credit")}</MenuItem>
                </Select>
                <TextField
                  required
                  id="Amount"
                  className="inputStyle"
                  name="Amount"
                  label={t("Amount")}
                  fullWidth
                  InputProps={{ inputProps: { min: 0, max: maxAmount } }}
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  type="number"
                  margin="dense"
                />
                <TextField
                  required
                  id="User"
                  name="User"
                  label={t("User email")}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="inputStyle"
                  fullWidth
                  margin="dense"
                />
                <TextField
                  required
                  id="Transfer purpose"
                  name="Transfer purpose"
                  label={t("Transfer purpose")}
                  onChange={(e) => setPurpose(e.target.value)}
                  type="text"
                  className="inputStyle"
                  fullWidth
                  margin="dense"
                />
                <div className="formBtn">
                  <Button onClick={() => setShow(false)} className="blueBtn">
                    {t("cancel")}
                  </Button>
                  <Button onClick={onSend} className="blueBtn">
                    {" "}
                    {t("send")}
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
