import * as React from "react";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import logo from "../../assets/images/logo_cellcoin.png";
import "./rules.css";
import { Box, Container } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const theme = createTheme({
  direction: "rtl",
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export default function Rules({ setItem }) {
  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mx: "auto",
              background: "var(--secondary)",
              width: "66%",
              borderRadius: "50%",
              aspectRatio: "3/2",
              boxShadow: "0px 0px 20px 0px rgb(42 134 159 / 10%)",
            }}
          >
            <img
              src={logo}
              style={{
                objectFit: "contain",
                width: "80%",
              }}
            />
            <span
              style={{ marginRight: 20 }}
              className="backIcon isLink"
              onClick={() => setItem("")}
            >
              <ArrowForwardIosIcon />
            </span>
          </Box>
          <h5
            style={{
              textAlign: "center",
              marginTop: "25px",
            }}
          >
            <b>תקנון, תנאי שימוש ומדיניות הפרטיות</b>
          </h5>
          <div className="rules">
            <ol>
              <li>
                <h5>כללי</h5>
                א. הוראות התקנון מנוסחות בלשון זכר לצרכי נוחות בלבד ומתייחסות
                לנשים וגברים כאחד
                <br />
                ב. האתר מנוהל ומופעל ע"י אלעד מועדוני צרכנות בע"מ (להלן: "מנהל
                האתר").
              </li>
              <li>
                <h5>הצטרפות (הורדת) אפליקציית ארנק ההטבות.</h5>
                א. כל אדם שמלאו לו 18 שנים, רשאי לבקש להצטרף למועדון כרטיס
                האשראי - cellcoin, המופעל ומנוהל ע"י מקס איט פיננסים בע"מ (להלן:
                "מקס")
                <br />
                ב. מובהר, כי ההצטרפות למועדון וקבלת הטבות המועדון, הינם ללקוחות
                פרטיים ולרווחתם.
                <br />
                ג. כל אדם המעוניין להיות חבר המועדון, מוזמן לפנות לאחד מסניפי
                הרשת להתקבל כחבר במועדון הלקוחות באמצעות בקשה להנפקת כרטיס אשראי
                cellcoin המונפק ע"י מקס איט פיננסים בע"מ והכל בכפוף לתנאים
                וההגבלות כמצוין בתנאי ההצטרפות לקבלת כרטיס אשראי המועדון, לרבות
                טופס גילוי נאות ולרבות התנאים המפורטים בתקנון זה.
                <br />
                ד. היה והצטרף אדם כחבר במועדון האשראי, יהא זכאי להשתמש באפליקציה
                של ארנק ההטבות – cellcoins (להלן: "ארנק ההטבות").
              </li>
              <li>
                <h5> צבירת מטבעות cellcoins ע"פ תכנית ה- cashback</h5>
                א. מחזיקי ארנק ההטבות יזוכו במטבעות בעבור כל רכישה בחנויות הרשת.
                <br />
                ב. יתרת המטבעות בארנק ההטבות תעודכן תוך 2 ימי עסקים ממועד
                הקנייה.
                <br />
                ג. קבלת ההטבה מותנית בכך שהקנייה תבוצע באמצעות כרטיס אשראי
                המועדון.
                <br />
                ד. אופן ושיעור הצבירה: בגין כל רכישה, תזוכה יתרת המטבעות בארנק
                ההטבות בשיעור של 8% משווי הקניה. לדוגמא: ברכישה בסך של 600 ₪
                ממוצרי הרשת מחזיק ארנק ההטבות יצבור 48 מטבעות.
                <br />
                ה. תוקף המטבעות הינו למשך 12 חודשים.
                <br />
                ו. מנהל האתר ו/או מקס רשאים לשנות את השיטה לצבירת מטבעות, וזאת
                על פי שיקול דעתם הבלעדית.
                <br />
                ז. מנהל האתר ו/או מקס רשאים לשנות את יחס/מקדם המרת המטבעות ו/או
                הטבות שונות, וזאת על פי שיקול דעתם הבלעדית.
                <br />
                ח. מנהל האתר רשאי להגביל ו/או להתנות את ההטבות או המבצעים על פי
                שיקול דעתו הבלעדי.
                <br />
                ט. מנהל האתר יהיה רשאי לעשות שימוש בתשתית הטכנולוגית של המועדון
                לצורך מתן הטבות / מענקים / זכאויות לקבוצות לקוחות על פי שיקול
                דעתו הבלעדי.
                <br />
                י. מובהר כי מנהל האתר ו/או מקס רשאים להפסיק את מתן ההטבות ו/או
                לשנות את אופיין ו/או אופן חישוב ההטבות בכל עת ועל פי שיקול דעתם
                הבלעדית וללא הודעה מוקדמת, ולא תישמע מצד הלקוח כל טענה לרבות
                בגין הסתמכות על מתן ההטבות בהצטרפות כחבר מועדון.
              </li>
              <li>
                <h5>המרה/מימוש ויתרת מטבעות</h5>
                א. מנהל האתר יאפשר לחברי המועדון להמיר מטבעות שנצברו לזכותם
                ולקבל כנגדם הנחות במגוון רשתות ו/או הנחות בקניית שוברים שונים,
                כפי שיפורסם מעת לעת.
                <br />
                ב. יובהר כי, למנהל האתר אין התחייבות כלשהי לפדות את יתרת המטבעות
                במזומן.
                <br />
                ג. מימוש המטבעות הינו בכפוף לתנאים הבאים: 1. לצורך מימוש
                המטבעות, נדרשת פעולה אקטיבית של החבר באמצעות האפליקציה או האתר.
                2. החבר יידרש להזדהות בהתאם לדרישות הזיהוי של מנהל האתר כפי
                שיהיו מעת לעת. 3. מימוש מטבעות בבתי העסק המכבדים, תתבצע מתוך
                אזור ה"ארנק" הנמצא באפליקציה. בלחיצה על כפתור "תשלום בבית עסק"
                (באזור הארנק), יופיע מחשבון בו יידרש החבר להקליד את הסכום המלא
                של העסקה, ולאשר לתשלום את הסכום (המופחת) שיופיע לאחר ניכוי
                המטבעות. את הקוד/ברקוד המתקבל יציג החבר לקופאי/ת. 4. מימוש
                המטבעות אפשרי ברשימת הרשתות המפורטות (באזור הארנק), תחת הכפתורים
                "מסעדות" ו/או "אופנה". 5. זהות הרשתות עשויה להשתנות ללא הודעה
                מוקדמת.
              </li>
              <li>
                <h5>עדכון פרטים אישיים</h5>
                א. בהצטרפות לאתר מאשר החבר כי המידע שנמסר על ידו ו/או ייאסף
                אודותיו (להלן: "המידע"), יישמר במאגרי המידע של מנהל האתר לצורך
                ייעול השירות והקשר עמו, לרבות לטובת צרכים תפעוליים, שיווקיים
                ודיוור ישיר.
                <br />
                ב. יובהר כי לא חלה על החבר חובה חוקית למסור את המידע ומסירתו
                הינה מרצונו ובהסכמתו.
                <br />
                ג. מנהל האתר יהיה רשאי להעביר את המידע לצדדים שלישיים (ובלבד
                שיתחייבו לשמור על פרטי החבר האישיים כנדרש על -פי דין), הנותנים
                שירות למנהל האתר, לצורך תפעול ההסדרים הנוגעים למועדון כמפורט
                בתקנון זה. מנהל האתר רשאי להסתייע בשירותים של ספקים חיצוניים
                באספקת שירות לחבר, אשר יתכן שתהיה גם להם גישה למידע בסיסי אודות
                החבר.
                <br />
                ד. בהתאם להסכמת החבר כדין, מנהל האתר ו/או מקס יהיו רשאים לפנות
                אל החבר בדיוור ישיר באמצעות דואר אלקטרוני, מסרונים (SMS), רשתות
                חברתיות, ובטלפון, וזאת כל עוד לא נתקבלה הודעה אחרת מהחבר בכתב.
                <br />
                ה. במסגרת ההרשמה יידרש החבר למסור מידע אישי לגביו, כדוגמת שם
                מלא, דרכי ההתקשרות עמו והכל בהתאם לתנאי השימוש של האתר או
                האפליקציה.
                <br />
                ו. באחריות החבר לדווח למנהל האתר בדבר שינוי בפרטיו האישיים. מנהל
                האתר לא יהיה אחראי לאי קבלת הטבה ו/או כל עדכון בקשר עם האתר או
                האפליקציה הנובע מאי עדכון פרטיו האישיים של החבר במועד.
                <br />
                ז. החבר מסכים בזאת באופן מפורש לשימוש במידע כאמור לעיל ומאשר כי
                השימוש בו לא ייחשב כפגיעה בפרטיותו ולא יזכה את החבר בכל סעד ו/או
                פיצוי. החבר רשאי לבטל את הסכמתו זו על ידי מתן הודעה בכתב למנהל
                האתר.
              </li>
              <li>
                <h5>ביטול חברות באתר/אפליקציה</h5>
                א. מנהל האתר יהא רשאי לבטל חברותו של חבר/משתמש, מכל סיבה שהיא
                מבלי שתהא לו חובה לנמק את סיבת הביטול ולרבות במידה והחבר לא עמד
                בהתחייבויותיו כלפי מנהל האתר ו/או מקס.
                <br />
                ב. מנהל האתר ו/או מקס יוכלו להודיע בכל דרך שימצאו לנכון על הפסקת
                פעילות האתר בהודעה כנדרש על פי דין. במקרה של הפסקת פעילות האתר
                כאמור, תינתן לחבר האפשרות לממש מטבעות שנצברו בהתאם להוראות תקנון
                זה, למשך תקופה הנדרשת על פי דין.
              </li>
              <li>
                <h5>אבטחת מידע והגנת הפרטיות</h5>
                א. מנהל האתר ישתמש באמצעי הגנה והצפנה SSL הנהוגים בתחום הסחר
                האלקטרוני בישראל ובארה"ב לפעולות של מסירת מידע מקוון, ובהתאם
                לנדרש על פי כל דין. אמצעי ההגנה האמור נועדו לאבטחת זרימת מידע
                מוצפנת בין מחשב חבר המועדון בזמן ביצוע הרכישה לבין שרתי המערכת.
                <br />
                ב. מנהל האתר ינהג במידע ובפרטים האישיים שנמסרו במסגרת השימוש
                באתר בהתאם ובכפוף להוראות כל דין. מבלי לגרוע מן האמור, מנהל האתר
                יהא רשאי להשתמש בפרטים האישיים ובמידע שמסרו החברים כאמור וכן
                לאסוף מידע אודות דפוסי השימוש שלהם באתר ע"י רישום השימוש שיעשו
                באתר (לרבות באמצעות cookies) בכפוף לכל דין, במקרים הבאים: 1.
                לצורך העברתם לספקי התוכן על מנת להשלים את פעולת הרכישה שביצעו
                החברים באתר. 2. לצורך שיפור המידע והתכנים שמנהל האתר יציע במסגרת
                האתר לחברים באופן אישי ו/או לכלל ו/או לחלק מהחברים באתר. 3. על
                מנת לשלוח לחברים, מעת לעת, תכנים ו/או ליידע אותם בדבר מוצרים
                ושירותים שונים העשויים לעניין אותם והנמכרים על ידי מנהל האתר
                ו/או על ידי ספקי התוכן. 4. לצורך ניתוח מידע סטטיסטי על ידי מנהל
                האתר ו/או מסירתו לצדדים שלישיים לרבות למפרסמים ובלבד שמידע כאמור
                לא יזהה את החברים אישית באופן שמי. 5. לצורך יצירת קשר עם החברים
                במקרה הצורך. 6. לצורך תחזוקת האתר ו/או המערכת.
                <br />
                ג. ידוע לחברים כי מנהל האתר מקדיש משאבים ונוקט אמצעים מחמירים
                למניעת חדירה לאתר ולסיכול פגיעה אפשרית בפרטיותם, אולם הוא אינו
                יכול למנוע שיבושים באתר באופן מוחלט.
              </li>
              <li>
                <h5>כללי</h5>
                א. למנהל האתר נשמרת הזכות להוסיף, לשנות ולהחליף בכל עת את תנאי
                התקנון, ללא הודעה מוקדמת ועל פי שיקול דעתו הבלעדי והמוחלט, ובכלל
                זאת הזכות לפרש את הוראותיו, בכפוף לדין. החל ממועד השינוי של
                התקנון יחייב את האתר נוסחו החדש בלבד. נוסחו המחייב של התקנון הוא
                זה שיופיע באתר/אפליקציה בלבד.
                <br />
                ב. מנהל האתר יהא רשאי לשנות את שם המועדון, סמליו וכד' בכל עת לפי
                שיקול דעתו הבלעדי בלבד.
                <br />
                ג. מתן הטבות לחברים הינם בכפוף לפעילות תקינה של מערכות התפעול
                והמחשוב של מנהל האתר וצדדים שלישיים רלוונטיים. במידה ויחולו
                תקלות אשר ימנעו באופן זמני את מתן ההטבות כאמור לעיל, יפעל מנהל
                האתר לטיפול בתקלות בהקדם ולא תהיה לחברים טענות או תביעות כנגד
                מנהל האתר בשל אי מתן ההטבות בתקופת התקלות והטיפול בהן.
                <br />
                ד. לחבר לא תהיה כל דרישה ו/או תביעה בקשר עם התקנון ו/או שינוי
                התקנון.
              </li>
            </ol>
          </div>
        </Container>
      </ThemeProvider>
    </CacheProvider>
  );
}
