import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as Locate } from "../../assets/icons/locate.svg";

import Header from "../header/Header";
import { getProductById, openSidebar } from "../../redux/actions-exporter";
import QuantitySelector from "../quantitySelector";
import widgetHelper from "../../helpers/widget";
import CloseIcon from "@mui/icons-material/Close";

import "../category/category.css";

export default function ProductDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = location?.state;
  const { network } = location?.state;
  const product = useSelector((state) => state.product.product);
  const [numbersOfItems, setNumbersOfItems] = useState(1);
  const [userPrice, setUserPrice] = useState();
  const [stores, setStores] = useState([]);
  const userId = useSelector((state) => state?.user?.user?.id);
  const geo = useSelector((state) => state.user.geo);

  useEffect(() => {
    setStores(
      product?.merchants?.filter((m) => m.network?.id === network?.network?.id)
    );
  }, [product]);
  useEffect(() => {
    dispatch(getProductById(id, geo.longitude, geo.latitude, 100000000));
  }, []); //eslint-disable-line

  const handleChangeQuantity = (e) => setNumbersOfItems(e.target.value);

  const handleAddProduct = (product) => {
    if (!userId) {
      widgetHelper.login(
        () => dispatch(openSidebar()),
        () => navigate("/login")
      );
    }
    // return navigate("/login");
    // dispatch(addToCart(product?.id, numbersOfItems, product?.bonusRuleId));
    navigate("/checkout", {
      state: {
        productId: id,
        amount: userPrice || product.price,
        discount: 0,
        type: "product",
        successURL: process.env.REACT_APP_API_PELECARD_LOADING_URL,
        errorURL: process.env.REACT_APP_API_PELECARD_LOADING_URL,
      },
    });
    // toast.success("The product has been successfully added");
  };

  useEffect(() => {
    if (product && product?.type === "gift") {
      navigate("/gift", {
        state: { product },
      });
    }
  }, [product]); //eslint-disable-line
  return (
    <div>
      {/* <Header isProduct={true} /> */}
      <div className="productDetails">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="productDetails-info">
                <div className="productDetails-heading">
                  <h5 className="productDetails-title">{product.title}</h5>

                  <h6 className="productDetails-price">
                    {/* {product?.oldPrice && (
                      <>
                        <s>₪{product.oldPrice}</s>&nbsp;
                      </>
                    )} */}
                    {product?.price > 0 ? (
                      <span>₪ {product.price}</span>
                    ) : (
                      <>
                        <input
                          type="number"
                          placeholder="סכום..."
                          value={userPrice}
                          onChange={(e) => setUserPrice(e.target.value)}
                          className="priceInput"
                        />{" "}
                        ₪
                      </>
                    )}
                  </h6>
                  <p className="productDetails-contentText">
                    {product?.content || ""}
                  </p>
                </div>

                <QuantitySelector
                  onChange={handleChangeQuantity}
                  minValue={1}
                  maxValue={5}
                  value={numbersOfItems}
                />
                <div className="productDetails-btns">
                  <Button
                    className="addcart_btn"
                    onClick={() => handleAddProduct(product)}
                  >
                    <span>{t("addToCart")}</span>
                  </Button>
                </div>

                <ul className="productDetails-List">
                  {product?.sku && (
                    <li className="productDetails-ListItem">
                      <strong>{t("SKU")}: </strong>
                      {product.sku}
                    </li>
                  )}
                  <li className="productDetails-ListItem">
                    <strong>{t("categories")}: </strong>
                    {product?.categories?.reduce(
                      (p, c, i) => p + (i > 0 ? ", " : "") + c.title,
                      ""
                    ) || t("No categories")}
                  </li>
                </ul>

                <div className="chains1">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "larger",
                      fontWeight: "bold",
                      padding: "10px 0",
                    }}
                  >
                    {network?.network?.name
                      ? "סניפי " + network?.network?.name
                      : "סניף " + network?.title}
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {stores
                      ?.sort((a, b) => Number(a.distance) - Number(b.distance))
                      .map((branch, i) => (
                        <>
                          {i !== 0 && (
                            <hr
                              style={{
                                margin: "5px",
                                borderColor: "#eee",
                              }}
                            />
                          )}
                          <div className="menuSearchItem">
                            <img
                              alt=""
                              className="menuSearchItemImg"
                              src={branch.image}
                            />
                            <div className="menuSearchItemText">
                              <div>{branch.title}</div>
                              <div>
                                {branch.address && (
                                  <div className="menuSearchItemText_sub">
                                    <Locate />
                                    {branch.address}
                                  </div>
                                )}

                                <div className="menuSearchItemText_sub sub">
                                  <div
                                    className={`range ${
                                      branch.distance ? "" : "hide"
                                    }`}
                                  >
                                    {branch.distance > 1
                                      ? Math.round(branch.distance) + ' ק"מ'
                                      : Math.round(
                                          (branch.distance -
                                            Math.floor(branch.distance)) *
                                            1000
                                        ) + " מ'"}
                                  </div>
                                  {branch.kosher !== "לא כשר" && (
                                    <div className="kosher">
                                      {branch.kosher}
                                    </div>
                                  )}
                                </div>

                                {/*    <div className="menuSearchItemText_sub sub">

                                   <div className="range">
                                    {branch.distance > 1
                                      ? Math.round(branch.distance) + ' ק"מ'
                                      : Math.round((branch.distance - Math.floor(branch.distance)) * 1000) + " מ'"
                                    }
                                  </div> 
                                  {branch.kosher !== "לא כשר" &&
                                    <div className="kosher">
                                      {branch.kosher}
                                    </div>}
                                </div>*/}
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="productDetails-img">
              {product?.discount && (
                <div className="product-box__discount single">
                  <span className="product-box__off">
                    {product.discountType ? "" : "-"}
                    {product.discount}
                    {product.discountType}
                  </span>
                </div>
              )}
              <img src={product?.image} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
